@media screen and (min-width: 680px) {
.top-header-container{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    display: flex;
    width: 100%;
    background-color: whitesmoke;
    height: 10vh;
    box-shadow: 0px 4px 12px 0px rgb(0 0 0 / 20%);
}
.top-logo-container{
    width: 13%;
    padding: 12px;
    border-right: 0.1333rem solid #bfc5d2;
    font-size: larger;
    position: relative;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.top-side{
    width: 85%;
    padding: 8px 17px;
}
.menu-img{
    height: 25px;
    margin-top: 10px;
    cursor: pointer;
}
.logo-img{
    height: 40px;
    margin-left: 4%;
}
.top-header-icon{
    height: 18px;
    margin-right: 2%;
}
.logo-slaes{
    
    font-size: 20px;
    position: absolute;
    top: 27px;

}
.top-hide-menu{
    display: flex;
}
.top-header-menu{
    margin-right: 10px;
}
.reload-mobile{
    display:inline-block;
}
}
@media screen and (max-width: 480px) {
    .top-hide-menu{
        display: none;
    }
    .name{
        display: none;
    }
    .reload-mobile{
        display: none;
    }
    .top-header-container{
        display: flex;
        flex-direction: row;
        width: 100vw;
        height:max-content;
        box-shadow: 0px 4px 12px 0px rgb(0 0 0 / 20%);
     }
     .top-logo-container{
        width: 50%;
        padding: 13px 9px;
        position: relative;
        height: 10vh;
        display: flex;
     }
     .top-side{
         width: 50%;
         
     }
     .menu-img{
        height: 25px;
        margin-top: 10px;
         cursor: pointer;
     }
     .logo-img{
         height: 40px;
         margin-left: 4%;
     }
     .top-header-icon{
         height: 18px;
         margin-right: 2%;
     }
     .logo-slaes{
         
         font-size: 20px;
         position: absolute;
         top: 28px;
     
     }
}