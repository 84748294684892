@media screen and (min-width: 760px) {
   .room-Container{
    width: 90%;
     margin-left: 5%;
     margin-right: 5%;
   }
   .rooms{
    width: 100%;
    margin-top: 12vh;
   }
   .roomExpend{
    text-align: left;
    font-size: 30px;
    font-weight: 600;
   }
}