@media screen and (min-width: 760px) {
   .login{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
   }
    .Login-container{
    width: 20%;
    height: fit-content;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    padding: 28px 22px;
    border-radius: 27px;
   }
   .SignIN{
    text-align: left;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 12px;
   }
   .login-label{
    text-align: left;
    width: 100%;
    font-size: 18px;
    font-weight: 500;
   }
}