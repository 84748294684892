.addUserContainer{
   position: fixed;
   width: 22%;
   top: 25%;
   left: 30%;
   background-color: white;
   padding: 9px 22px;
   border-radius: 12px;
   box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}
.cross{
   position: absolute;
    right: 18px;
    cursor: pointer;
    font-size: 20px;
}
.adduser-btn{
   display: flex;
    width: 100%;
    margin-bottom: 15px;
    margin-left: 15px;
}